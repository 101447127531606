<template>
  <div class="app-container summary-page">
    <cent-tab
        ref="centtab"
        @send-type="sendType"
    ></cent-tab>
    <e-header
        :query="query"
        @toQuery="toQuery"
        @clear="clear"
    />
    <!--表格渲染-->
    <el-table
        v-loading="loading"
        :data="data"
        stripe
        size="small"
        style="width: 100%;"
        show-summary
        :summary-method="getSummaries"
        @sort-change="sortChange"
    >
      <el-table-column label="账户信息" width="150">
        <template slot-scope="scope">
          <span>
            {{ scope.row.accType }}
            <br/>
          </span>
          <span>
            {{ scope.row.login }}
            <br/>
          </span>
          <!-- <span>1:{{ scope.row.leverage }}</span> -->
          <span>{{ parseTime(scope.row.regDate) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="客户信息" width="160">
        <template slot-scope="scope">
          <span>
            {{ scope.row.userName }}
            <br/>
          </span>
          <span>
            {{ scope.row.id }}
            <br/>
          </span>
          <span>{{ scope.row.email }}</span>
        </template>
      </el-table-column>
      <el-table-column label="归属代理" width="110">
        <template slot-scope="scope">
          <span>
            {{ scope.row.agentName }}
            <br/>
          </span>
          <span>
            {{ scope.row.belongId }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="客户经理" width="110">
        <template slot-scope="scope">
          <span>
            {{ scope.row.managerName }}
            <br/>
          </span>
          <span>
            {{ scope.row.managerId }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
          label="余额"
          sortable
          prop="balance">
        <template slot-scope="scope">
          <span>
          {{ scope.row.balance || 0 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
          label="净值"
          sortable
          prop="equity">
        <template slot-scope="scope">
          <span>{{ scope.row.equity || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="平仓手数" 
          sortable
          prop="volume">
        <template slot-scope="scope">
          <span>{{ scope.row.volume || 0 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="总返佣"
          sortable
          prop="producedCommission">
        <template slot-scope="scope">
          <span>
            {{ scope.row.producedCommission || 0 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="总盈亏"
          sortable
          prop="profit">
        <template slot-scope="scope">
          <span>
            {{ scope.row.profit || 0 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="总入金" 
          sortable
          prop="deposit">
        <template slot-scope="scope">
          <span>
            {{ scope.row.deposit || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="佣金内转" 
          prop="commissionTransfer"/>
      <el-table-column label="总出金"
          sortable
          prop="withdraw">
        <template slot-scope="scope">
          <span>
            {{ scope.row.withdraw || 0 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="净入金"
          sortable
          prop="netDeposit">
        <template slot-scope="scope">
          <span>{{ scope.row.netDeposit || 0 }}</span>
        </template>
      </el-table-column>

    </el-table>
    <!--分页组件-->
    <public-pagination
        :total-page="total"
        :current-page="page"
        @pageChange="pageChange"
        @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initDataMix from '@/mixins/initData'
import { parseTime,parseDate } from '@/utils/index'
import eHeader from '@/components/stat/account/header'
import { init_date } from '@/utils/index'

export default {
  name: 'accountTotal',
  components: {
    eHeader
  },
  mixins: [initDataMix],
  data() {
    return {
      delLoading: false,
      statistics: {
        balanceSum: '',
        equitySum: '',
        volumeSum: '',
        producedCommissionSum: '',
        profitSum: '',
        depositSum: '',
        withdrawSum: '',
        netDepositSum: '',
      },

      currency: 'USD'
    }
  },
  created() {
    this.initDate();
    this.$nextTick(() => {
      this.init()
    })
  },
  updated() {
    this.$nextTick(() => {
      this.setSumProfitColor()
    })
  },
  computed: {
  },
  methods: {
    parseTime,
    parseDate,
    checkPermission,
    sendType(type) {
      this.currency = type
      this.page = 0
      this.init()
    },
    initDate(){
      let dateInfo = init_date();
      this.query.endTime = dateInfo.today;
      this.query.startTime = dateInfo.firstday;
    },
    beforeInit() {
      //加入了美分账户
      this.url = 'crm/getTCustMtaccountStatistics'
      this.params = {
        page: this.page,
        size: this.size,
        currency: this.$refs.centtab.type
      }
      if(this.sort){
        this.params.sort = this.sort;
      }
      const query = this.query
      const mt4Info = query.mt4Info
      const custInfo = query.custInfo
      let manageInfo = query.manageInfo
      const agentInfo = query.agentInfo

      if (mt4Info) {
        this.params['mt4Info'] = mt4Info
      }
      if (custInfo) {
        this.params['custInfo'] = custInfo
      }
      if (agentInfo) {
        this.params['agentInfo'] = agentInfo
      }
      if (manageInfo) {
        this.params['manageInfo'] = manageInfo
      }

      if (this.query.startTime) {
        this.params.startTime = this.parseDate(this.query.startTime)
      }
      if (this.query.endTime) {
        this.params.endTime = this.parseDate(this.query.endTime)
      }
      //脱敏标志
      this.params['flag'] = true
      return true
    },
    getAccountType(row) {
      if (row.businessType == 6) {
        return '体验账户'
      } else if (row.businessType == 5) {
        return '模拟账户'
      } else if (row.businessType == 3) {
        return '钱包账户'
      } else if (row.businessType == 1) {
        if (row.tier == 1) {
          return 'Social(信号)'
        } else {
          return 'Social(投资)'
        }
      } else if (row.businessType == 2) {
        if (row.tier == 1) {
          return 'MAM(信号)'
        } else {
          return 'MAM(投资)'
        }
      } else {
        return '普通账户'
      }
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        switch (column.label) {
          case '账户信息':
            sums[index] = '合计'
            break
            // white-space 换行
          case '余额':
            sums[index] = this.statistics.balanceSum && this.statistics.balanceSum.toFixed(2);
            break
          case '净值':
            sums[index] = this.statistics.equitySum && this.statistics.equitySum.toFixed(2);
            break
          case '平仓手数':
            sums[index] = this.statistics.volumeSum;
            break
          case '总返佣':
            sums[index] = this.statistics.producedCommissionSum;
            break
          case '总盈亏':
            sums[index] = this.statistics.profitSum;
            break
          case '总入金':
            sums[index] = this.statistics.depositSum;
            break
          case '佣金内转':
            sums[index] = this.statistics.commissionTransferSum;
            break
          case '总出金':
            sums[index] = this.statistics.withdrawSum;
            break
          case '净入金':
            sums[index] = this.statistics.netDepositSum;
            break           
        }
      })
      return sums
    },
    setSumProfitColor() {
      const s_table = document.getElementsByClassName('el-table__footer-wrapper')[0]
      if (s_table && s_table.getElementsByTagName('tr')[0]) {
        const child_tr_childivs = s_table.getElementsByTagName('tr')[0].childNodes
        child_tr_childivs.forEach((item, index) => {
          if (index == 5 || index == 6 || index == 7 || index == 8) {
            let divEle = item.getElementsByClassName('cell')[0]
            divEle.setAttribute('style', 'white-space: pre-wrap')
          }
        })
      }
    },
    clear() {
      this.query = {}
      this.toQuery()
    }
  }
}
</script>
<style lang="less" scoped>
.my-button {
  margin-right: 5px;
}
</style>
<style scoped>
.tag-outer {
  cursor: pointer;
  color: #409EFF;
}

.code-font {
  color: black;
  font-size: 16px;
  font-weight: 600;
}

.send-li li {
  display: inline-block;
  margin-right: 20px;
}

.count {
  padding: 10px 30px;
  background-color: rgb(30, 144, 255);
  color: aliceblue;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
</style>

